import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App.js'
import reportWebVitals from './reportWebVitals.js'
import { StoreProvider } from './misc/Store.js'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById('root'),
)

reportWebVitals()
