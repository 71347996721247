import * as AppActions from './AppActions.js'

const allActions = [AppActions]

const actions = allActions.reduce((prev, curr) => {
  delete curr.default
  return Object.assign(prev, curr)
}, {})

const useActions = (store) => {
  return Object.keys(actions).reduce((prev, key) => {
    if (actions[key].toString().indexOf('return function') > -1) {
      return { ...prev, [key]: actions[key](store) }
    }
    return { ...prev, [key]: actions[key] }
  }, {})
}

const exportActions = { useActions }

export default exportActions
