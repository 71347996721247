import { useState, useEffect } from 'react';
import './RenderClock.css';

function RenderClock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (date) => {
    const options = {
      timeZone: 'Europe/Ljubljana',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    return date.toLocaleTimeString('sl-SI', options).substring(0, 8);
  };

  const renderTimeWithFixedWidth = (formattedTime) => {
    return formattedTime.split('').map((char, index) => (
      <span key={index} className={`fixed-width-char${char === ':' ? ' colon' : ''}`}>
        {char}
      </span>
    ));
  };

  return <div className="clock">{renderTimeWithFixedWidth(formatTime(time))}</div>;
}

export default RenderClock;
