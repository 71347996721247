import React from "react";
import PropTypes from "prop-types";
import { useLocalStore } from "mobx-react-lite";
import storeActions from "../actions/index.js";

const StoreContext = React.createContext();

const createStore = () => {
  const store = {
    extruderTemperature: 1234,
  };
  return store;
};

export const StoreProvider = ({ children }) => {
  const store = useLocalStore(createStore);

  const actions = storeActions.useActions(store);
  return <StoreContext.Provider value={{ store, actions }}>{children}</StoreContext.Provider>;
};

StoreProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useStore = () => {
  return React.useContext(StoreContext);
};
